import React from "react";

import Layout from "../../../components/Layout";
import Breadcrumb from "../../../components/Breadcrumb";
import { helpCenterItems } from "../../../lib/helpCenterItems";

import * as styles from "./HelpCenterItem.module.css";
import { addSlash } from "../../../lib/helpers";

const HelpCenterItem = ({ children, path }) => {
  const pathMatch = path && path.match(/help-center\/(.+)\//);
  const articleID = pathMatch && pathMatch[1];
  const articleObj = articleID && helpCenterItems.find((item) => item.id === articleID);
  const title = articleObj && articleObj.title;

  return (
    <Layout
      metaDesc={`Tobo App Answer for a frequently asked question: ${title}.`}
      title={title}
      ogUrlPath={addSlash(path)}
      canonicalPath={addSlash(path)}
    >
      <main className={styles.container}>
        <Breadcrumb items={[{ label: "Tobo Help Center", to: "/help-center" }, { label: title }]} />
        <h1>{title}</h1>
        <div>{children}</div>
      </main>
    </Layout>
  );
};

export default HelpCenterItem;
