import React from "react";
import { Link } from "gatsby";

import * as styles from "./Breadcrumb.module.css";

const Breadcrumb = ({ items }) => {
  return (
    <ol className={styles.container}>
      {items.map((item) => (
        <li key={item.label}>
          {item.to ? <Link to={item.to}>{item.label}</Link> : <span>{item.label}</span>}
        </li>
      ))}
    </ol>
  );
};

export default Breadcrumb;
