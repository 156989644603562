import React from "react";

import HelpCenterItem from "../../containers/HelpCenter/HelpCenterItem";

const HelpCenterArticle = ({ path }) => {
  return (
    <HelpCenterItem path={path}>
      <div>
        <p>
          You can use the same account to login to multiple Tobo apps, however each subscription is
          limited to only one app and one language.
        </p>
      </div>
    </HelpCenterItem>
  );
};

export default HelpCenterArticle;
