const helpCenterItems = [
  {
    title: "There is no sound",
    id: "no-sound",
  },
  {
    title: "I’ve already purchased but can’t see Premium features",
    id: "already-purchased-no-premium",
  },
  {
    title: "How can I sync or save my progress?",
    id: "how-to-save-progress",
  },
  {
    title: "How can I cancel my subscription?",
    id: "cancel-subscription",
  },
  {
    title: "Is Tobo free and what is included in the subscription?",
    id: "is-free",
  },
  {
    title: "How do I delete my account?",
    id: "delete-account",
  },
  {
    title: "Can I use Tobo on multiple devices?",
    id: "multiple-devices",
  },
  {
    title:
      "Can I learn multiple languages with the same account and subscription?",
    id: "multi-language-subscription",
  },
];

module.exports = {
  helpCenterItems,
};
